import { useLocalization } from "gatsby-theme-i18n";
import { navigate } from "gatsby";
import { NavigateFn, useLocation } from "@gatsbyjs/reach-router";

type ReturnType = {
  navigate: NavigateFn;
  delocalizedPath: string;
};

export function useLocalizedHelpers(): ReturnType {
  const { defaultLang, prefixDefault, locale, localizedPath } = useLocalization();
  const { pathname } = useLocation();
  const localizedNavigate: NavigateFn = (to, options?) => {
    if (typeof to === "number") {
      return navigate(to);
    }
    return navigate(
      localizedPath({
        defaultLang,
        prefixDefault,
        locale,
        path: to,
      }),
      options
    );
  };

  return {
    navigate: localizedNavigate,
    delocalizedPath: pathname.replace(`${locale}/`, ""),
  };
}
