import * as React from "react";
import {Menu, Transition} from "@headlessui/react";
import {MdExpandMore, MdGTranslate} from "react-icons/md";
import {useLocalization, LocalizedLink as Link} from "gatsby-theme-i18n";
import {useLocalizedHelpers} from "../hooks/useLocalizedHelpers";
import {useMediaQuery} from "react-responsive";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LanguageSelect: React.FC = () => {
    const isMobile = useMediaQuery({maxWidth: 767});
    const {config} = useLocalization()
    const {delocalizedPath} = useLocalizedHelpers()
    return <Menu as="div" className="relative inline-block text-left">
        <div>
            {isMobile ? <Menu.Button
                    className="flex justify-center items-center text-white hover:text-orange focus:text-orange">
                    <MdGTranslate className="h-7 w-7" aria-hidden="true"/>
                </Menu.Button> :
                <Menu.Button
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    Language
                    <MdExpandMore className="w-5 h-5 ml-2 -mr-1"/>
                </Menu.Button>}
        </div>

        <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    {config.map((elem) =>
                        <Menu.Item key={elem.code}>
                            {({active}) => (
                                <Link
                                    to={delocalizedPath}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm'
                                    )}
                                    language={elem.code}
                                    replace
                                >
                                    {elem.localName}
                                </Link>
                            )}
                        </Menu.Item>)}
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}

export default LanguageSelect;
