import * as React from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { navigate } from "gatsby";
import { MdArrowBack } from "react-icons/md";
import Fade from "react-reveal/Fade";
import { useMediaQuery } from "react-responsive";
import { IconType } from "react-icons";
import { StaticImage } from "gatsby-plugin-image";
import LanguageSelect from "./languageSelect";

type Props = {
  title: string;
  navButton?: {
    icon: IconType;
    location: string;
  };
  backButton?: boolean;
};

const NavBar: React.FC<Props> = ({ title, navButton, backButton = true }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Fade top>
      <div className="flex justify-between w-screen border-b-2 border-orange min-h-16 z-9999">
        <div className={`flex flex-shrink justify-start items-center ${backButton ? "w-24 md:w-52" : "w-20 md:w-40"}`}>
          {backButton === true ? (
            <button
              type="button"
              aria-label="Back"
              tabIndex={0}
              className={` text-white text-2xl cursor-pointer h-10 px-1 py-2 
                        transition duration-500 ease-in-out transform hover:scale-110 hover:text-orange `}
              onClick={() => navigate(-1)}
            >
              <MdArrowBack />
            </button>
          ) : null}
          <Link
            to="/"
            className="flex items-center justify-center h-14 cursor-pointer transition duration-500 ease-in-out transform hover:scale-105 ml-2"
          >
            {isMobile ? (
              <StaticImage
                src="../images/icon.png"
                alt="qubtimetable icon"
                placeholder="tracedSVG"
                layout="constrained"
              />
            ) : (
              <StaticImage
                src="../images/logo.png"
                alt="qubtimetable logo"
                placeholder="tracedSVG"
                layout="constrained"
              />
            )}
          </Link>
        </div>
        <div className="flex flex-grow justify-center items-center">
          <p className="text-white text-xl text-center">{title}</p>
        </div>
        <div
          className={`flex flex-shrink justify-end items-center mr-2 ${backButton ? "w-24 md:w-52" : "w-20 md:w-40"}`}
        >
          <LanguageSelect />
          {navButton == null ? null : (
            <Link
              aria-label="Nav Action"
              className={`flex text-white align-middle items-center justify-center text-2xl cursor-pointer h-10 px-2 py-2 
                        transition duration-500 ease-in-out transform hover:scale-110 hover:text-orange`}
              to={navButton.location}
            >
              {React.createElement(navButton.icon, { className: "h-7 w-7" })}
            </Link>
          )}
        </div>
      </div>
    </Fade>
  );
};
export default NavBar;
